import { Row, Col } from "react-bootstrap";
import CustomCheckbox from "./CustomCheckbox";
import { useSortable } from '@dnd-kit/sortable';
import { InputText } from "../Input";
import { strings, utility } from "@ocean-knight/shared";
import { useState } from "react";
import common from "../../common/common";
import { I18NTYPE } from "@ocean-knight/shared/src/utility";
import "./GetFormItem.css";
import _ from "lodash";

const TYPE = utility.FORM_ITEM_TYPE;
const CHART_TYPE = utility.CHART_TYPE;

// form box 생성
export default function GetFormItem({
    form,
    index,
    activeIndex,
    switchActive,
    isValid,
    errors,
    forceUpdate,
    models,
    getFormContent,
    addFormItem,
    copyFormItem,
    removeFormItem,
    dispLang
}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({ id: form._id });

    console.assert(dispLang == "ko" || dispLang == "en");

    const style = {
        transform: transform ? `translate3d(${transform?.x}px, ${transform?.y}px, 0px)` : "",
        transition,
    };

    return (
        form.essential ? <></> // 필수 폼 나오지 않도록 임시 수정
            : <Row
                ref={!form.essential ? setNodeRef : undefined}
                style={style}
                className={`gx-0 form-item-row font-size-14 notosanskr-400 ${index === activeIndex.current ? "activated" : ""} bg-white`}
                key={form._id}
                id={form._id}
                onClick={(e) => !form.essential && index !== activeIndex.current && switchActive(e.target.closest(".form-item-row"), index)}
            >
                <Col className="box">
                    {!form.essential && // essential은 수정을 허용하지 않음
                        <Row className="gx-0">
                            <Col className="drag-button">
                                <img src={process.env.PUBLIC_URL + `/drag.png`} ref={setActivatorNodeRef}  {...attributes} {...listeners} alt="" />
                            </Col>
                        </Row>
                    }
                    <Row className="gx-0">
                        <Col className="title col-auto">
                            <InputText
                                key={dispLang}
                                id="title"
                                name={index}
                                disabled={form.essential}
                                defaultValue={form.fields.title}
                                placeholder={strings[dispLang]['275']}
                                onBlur={(e) => {
                                    form.fields.title = e.target.value;
                                    form.built_in = false;
                                    isValid([e.target]);
                                }}
                                className={`${errors[`${index}`]?.message ? "invalid" : ""}`}
                            />
                            <div className="c-f00 mt-5px">{errors[`${index}`]?.message}</div>
                        </Col>
                        {!form.essential && // essential은 수정을 허용하지 않음
                            <Col className="select">
                                <img src={process.env.PUBLIC_URL + `/icon_form.png`} srcSet={`${process.env.PUBLIC_URL}/icon_form@2x.png 2x`} alt="" />
                                <select
                                    onChange={(e) => {
                                        const type = e.target.value;
                                        form.type = type;
                                        form.fields = {
                                            title: form.fields.title,
                                            description: form.fields.description,
                                            required: form.fields.required,
                                        };
                                        form.sub_items = [];
                                        form.built_in = false;

                                        Object.keys(I18NTYPE).forEach(lang => {
                                            const fromFields = lang == dispLang ? form.fields : (form.i18n?.[lang]?.fields || form.fields);
                                            form.i18n[lang].fields = common.fieldsWithoutEmptyValue(fromFields, ["title", "description"]);
                                            form.i18n[lang].sub_items && delete form.i18n[lang].sub_items;
                                        });

                                        if ([TYPE.Checkbox, TYPE.Radiobox, TYPE.VendorUI, TYPE.VendorUI3].includes(type)) {
                                            if (!form.chart) { form.chart = {}; }
                                            form.chart.type = form.chart.type || "none";
                                        } else {
                                            form.chart = null;
                                        }

                                        if (type == TYPE.VendorUI3) {
                                            if (models.length > 1) {
                                                form.fields.object_detection_model = models.toSorted((a, b) => b.version.localeCompare(a.version))[0]._id;
                                            } else {
                                                form.fields.object_detection_model = models[0]._id;
                                            }
                                        }

                                        forceUpdate();
                                    }}
                                    defaultValue={form.type}
                                    disabled={form.essential}
                                >
                                    <option value={TYPE.Textbox} selected>
                                        {strings[dispLang]['474']}
                                    </option>
                                    <option value={TYPE.Spinnerbox}>
                                        {strings[dispLang]['475']}
                                    </option>
                                    <option value={TYPE.Textarea}>
                                        {strings[dispLang]['476']}
                                    </option>
                                    <option value={TYPE.Radiobox}>
                                        {strings[dispLang]['477']}
                                    </option>
                                    <option value={TYPE.Checkbox}>
                                        {strings[dispLang]['478']}
                                    </option>
                                    <option value={TYPE.File}>
                                        {strings[dispLang]['479']}
                                    </option>
                                    <option value={TYPE.VendorUI}>
                                        {strings[dispLang]['129']}
                                    </option>
                                    <option value={TYPE.VendorUI3}>
                                        {strings[dispLang]['480']}
                                    </option>
                                    <option value={TYPE.VendorUI4}>
                                        {strings[dispLang]['699']}
                                    </option>
                                </select>
                            </Col>
                        }
                    </Row>
                    <Row className="gx-0 description">
                        <Col className="col-auto description-textarea">
                            <textarea
                                key={dispLang}
                                id="description"
                                disabled={form.essential}
                                defaultValue={form.fields.description}
                                placeholder={strings[dispLang]['481']}
                                onBlur={(e) => {
                                    form.fields.description = e.target.value;
                                    form.built_in = false;
                                }}
                            />
                        </Col>
                        {form.type == TYPE.VendorUI3 &&
                        <Col className="select">
                            <img src={process.env.PUBLIC_URL + `/icon_ai.png`} alt="" title="Select AI Model" />
                            <select
                                key={form.fields.object_detection_model || ""}
                                className="object-detection-model-select"
                                onChange={(e) => {
                                    if (!e.target.value) {  // e.target.value == ""
                                        // not set
                                        delete form.fields.object_detection_model;
                                        // clear class_number settings
                                        form.sub_items.forEach(v => delete v.class_number);
                                    }
                                    else if (e.target.value == form.fields.object_detection_model) {
                                        // same item; no change
                                    }
                                    else if (e.target.value != form.fields.object_detection_model) {
                                        if (!form.fields.object_detection_model) {
                                            // previous not set; update object_detection_model
                                            form.fields.object_detection_model = e.target.value;
                                        }
                                        else {
                                            // different model
                                            const modelList = models;
                                            console.log(modelList);

                                            // get both model info
                                            const prevModel = modelList.filter(item => item._id == form.fields.object_detection_model)[0];
                                            const targetModel = modelList.filter(item => item._id == e.target.value)[0];

                                            // update classNumber when different model is selected
                                            form.sub_items.forEach(sub_item => {
                                                if (sub_item.class_number != -1) {
                                                    // get actual class name
                                                    let prevModelClassName = prevModel.classes[sub_item.class_number];

                                                    // get matching class's index (class_number) from targetModel
                                                    let foundIndex = -1;    // -1 : 'Not set'
                                                    for (let index = 0; index < targetModel.classes.length; index++) {
                                                        if (prevModelClassName == targetModel.classes[index]) {
                                                            foundIndex = index;
                                                            break;
                                                        }
                                                    }

                                                    // update class number
                                                    sub_item.class_number = foundIndex;
                                                }
                                            });

                                            // update object_detection_model
                                            form.fields.object_detection_model = e.target.value;
                                        }
                                    }

                                    // force update ui (since form var is not state, force updateState is necessary)
                                    forceUpdate();
                                }}
                                defaultValue = { form.fields.object_detection_model }
                                disabled={form.essential || !common.hasSiteAdminPermission() }
                            >
                                <option value={""}>Not set</option>
                                { models?.map(v =>
                                    <option key={v._id}
                                        value={v._id}
                                        selected={v._id == form.fields.object_detection_model}
                                        title={`version: ${v.version}\ntype: ${v.type}\ndescription: ${v.description}`}
                                    >{`${v.version}, desc: ${v.description}`}</option>
                                )}
                            </select>
                        </Col>
                        }
                    </Row>
                    <hr />
                    {!form.essential &&
                        <Row className="gx-0">
                            <div id="content">
                                {getFormContent(form, index, dispLang)}
                            </div>
                        </Row>
                    }
                </Col>
                {!form.essential && // essential은 수정을 허용하지 않음
                    <Col className="button-group col-auto">
                        <Row className="gx-0">
                            <div className="plus" onClick={(e) => addFormItem(e, index)}>
                                <img src={process.env.PUBLIC_URL + `/plus.png`} alt="" />
                            </div>
                        </Row>
                    </Col>
                }
                <div className="custom-hr" />
                <Row className="gx-0 bottom-button-group">
                    <Col>
                        {
                            [TYPE.Checkbox, TYPE.Radiobox, TYPE.VendorUI, TYPE.VendorUI3, TYPE.VendorUI4].includes(form.type) &&
                            <div>
                                <div className="select-chart">
                                    <div className="notosanskr-400 font-size-16 pe-3">
                                        {strings[dispLang]['700']}
                                    </div>
                                    <div className="select">
                                        <select
                                            onChange={(e) => {
                                                const chart_type = e.target.value;
                                                form.chart || (form.chart = {});
                                                form.chart.type = chart_type;
                                            }}
                                            defaultValue={form.chart?.type || ""}
                                            disabled={form.essential}
                                        >
                                            <option value={""} selected>
                                                {strings[dispLang]['700']}
                                            </option>
                                            <option value={CHART_TYPE.None} selected>
                                                {strings[dispLang]['701']}
                                            </option>
                                            <option value={CHART_TYPE.Pie}>
                                                {strings[dispLang]['702']}
                                            </option>
                                            <option value={CHART_TYPE.Doughnut}>
                                                {strings[dispLang]['703']}
                                            </option>
                                            <option value={CHART_TYPE.Horizontal}>
                                                {strings[dispLang]['704']}
                                            </option>
                                            <option value={CHART_TYPE.Vertical}>
                                                {strings[dispLang]['705']}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        {!form.essential &&
                            <div className="icon">
                                <img src={process.env.PUBLIC_URL + `/icon_add.png`} srcSet={`${process.env.PUBLIC_URL}/icon_add@2x.png 2x`}
                                    onClick={(e) => copyFormItem(e, index)} alt="" />
                                <img src={process.env.PUBLIC_URL + `/icon_garbage.png`} srcSet={`${process.env.PUBLIC_URL}/icon_garbage@2x.png 2x`}
                                    onClick={(e) => removeFormItem(e, index)} alt="" />
                                <span />
                            </div>
                        }
                        <CustomCheckbox
                            type="checkbox"
                            disabled={form.essential}
                            onChange={(e) => {
                                form.fields.required = e.target.checked;
                                form.built_in = false;
                            }}
                            defaultChecked={form.fields.required}
                        >
                            <span className="notosanskr-400 font-size-16">
                                {strings[dispLang]['482']}
                            </span>
                        </CustomCheckbox>
                    </Col>
                </Row>
            </Row>
    );
};
