import React, {useState, useRef, useCallback, useEffect} from "react";
import Pagination from "./Pagination";
import ReactModal from "react-modal";
import Api from "../../Api.js";
import "./AddressSearch.css";
import { InputText } from "../Input";
import Modal from "../common/Modal";
import { POPUP as GeneralPopup } from "../../common/defines";
import { IsPC } from "../common/MediaQuery";
import dgLogger from "../../common/dgLogger";
import { useTranslation, Trans } from "react-i18next";
import common from "../../common/common.js";

ReactModal.setAppElement("#root");

const POPUP = {
    ...GeneralPopup,
    Error: 1001,
};

const AddressSearch = (props) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        address: [],
        trySearch: false,
        popup: POPUP.None,
        popupTarget: null,
    });
    const isPc = IsPC();
    const currentPage = useRef(1);
    const paginationOption = useRef({ itemsCountPerPage: 5, pageRangeDisplayed: isPc ? 5 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const currentKeyword = useRef();
    const currentSelectItem = useRef(-1);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        document.getElementById("confirm") && (document.getElementById("confirm").disabled = true);
    }, [state.address]);

    useEffect(() => {
        paginationOption.current.pageRangeDisplayed = isPc ? 5 : 3;
        paginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        forceUpdate();
    }, [isPc, forceUpdate]);

    /**
     * api 호출 전 입력 된 문자열을 검사하고 api 호출
     * 출처: https://www.juso.go.kr/addrlink/devAddrLinkRequestGuide.do?menu=roadApi
     */
    const searchAfterCheck = () => {
        if (!document.getElementById("address").value.trim()) return;
        const expText = /[%=><]/;
        currentKeyword.current = document.getElementById("address").value.trim();
        if (expText.test(currentKeyword.current)) {
            setState({
                ...state,
                popup: POPUP.Error,
                popupTarget: t("646"),
            });
            return false;
        }

        const sqlArray = ["OR", "SELECT", "INSERT", "DELETE", "UPDATE", "CREATE", "DROP", "EXEC", "UNION", "FETCH", "DECLARE", "TRUNCATE"];

        for (let i = 0; i < sqlArray.length; i++) {
            const regex = new RegExp(sqlArray[i], "gi");

            if (regex.test(currentKeyword.current)) {
                setState({
                    ...state,
                    popup: POPUP.Error,
                    popupTarget: t("337", { "%s": sqlArray[i] }),
                });
                return false;
            }
        }

        getAddress();
    };

    /**
     * 주소를 받아오는 api 호출하는 함수
     */
    const getAddress = useCallback(() => {
        const payload = {
            currentPage: currentPage.current,
            countPerPage: paginationOption.current.itemsCountPerPage,
            keyword: currentKeyword.current,
            lang: common.getLang()
        };

        Api.getAddress(payload)
            .then((payload) => {
                const totalCount = parseInt(payload.common.totalCount);
                paginationOption.current.totalItemsCount = totalCount <= 4995 ? totalCount : 4995;
                setState((prev) => ({
                    ...prev,
                    address: payload.juso,
                    trySearch: true,
                }));
            })
            .catch((e) => {
                dgLogger.error(e)();
                setState((prev) => ({
                    ...prev,
                    popup: POPUP.Error,
                    popupTarget: e.message,
                }));
            });
    }, []);

    /**
     * pagination의 page가 바뀌었을 때 호출 되는 함수
     */
    const pageChange = useCallback(
        (page) => {
            currentPage.current = page;
            if (currentSelectItem.current !== -1) {
                document.getElementById(currentSelectItem.current).classList.remove("select-item");
                currentSelectItem.current = -1;
            }
            getAddress();
        },
        [getAddress]
    );

    const searchEnterkey = (e) => {
        if (e.key === "Enter") {
            searchAfterCheck();
        }
    };

    const selectAddress = (e) => {
        if (currentSelectItem.current !== -1) {
            document.getElementById(currentSelectItem.current).classList.remove("select-item");
        }
        e.target.classList.add("select-item");
        currentSelectItem.current = parseInt(e.target.id);
        document.getElementById("confirm").disabled = false;
    };

    const addressTable = () => {
        if (!state.address.length) {
            return (
                <div className="nanumsquare-15 nanumsquare-13:sm mt-32px" style={{ textAlign: "center" }}>
                    <Trans i18nKey={"561"} values={{ "%s": currentKeyword.current }} components={{ tag: <span className="c-4270e0" /> }} />
                </div>
            );
        } else {
            return (
                <>
                    <div className="address-table">
                        {state.address.map((address, index) => {
                            return (
                                <div className="address-table-item notosanskr-14 notosanskr-12:sm" id={index} onClick={selectAddress} key={address.roadAddr}>
                                    {t(
                                        "562",
                                        common.getLang() == "ko"
                                            ? { AA: address.roadAddr, BB: `${address.lnbrMnnm} - ${address.lnbrSlno}`, DD: address.zipNo }
                                            : { AA: address.roadAddr, BB: address.rn, DD: address.zipNo }
                                    )
                                        .split("\n")
                                        .map((v, i) => (
                                            <React.Fragment key={i}>
                                                {v}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                </div>
                            );
                        })}
                    </div>
                    <Pagination
                        ref={pageChange}
                        page={currentPage.current}
                        marginPagesDisplayed={paginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </>
            );
        }
    };

    const addressSearchConfirm = () => {
        if (currentSelectItem.current === -1) return;
        props.onComplete(state.address[currentSelectItem.current].roadAddr, "");
    };

    const popupError = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/icon_error.png`} srcSet={`${process.env.PUBLIC_URL}/icon_error@2x.png 2x, ${process.env.PUBLIC_URL}/icon_error@3x.png 3x`} alt="" />;
        const header = <div>{t("334")}</div>;
        const body = <div>{state.popupTarget}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Error) return popupError();
    };

    return state.trySearch && state.address.length ? (
        <ReactModal
            isOpen={true}
            // onRequestClose={() => {
            //     props.onCancel();
            // }}
            className={props.className ? props.className : "try-search"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"address-search"}
        >
            <div className="title nanumsquareeb-18 nanumsquareeb-16:sm">
                {t("330")}
                <div>
                    <img
                        src={process.env.PUBLIC_URL + `/icon_close.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`}
                        onClick={() => {
                            props.onCancel();
                        }}
                        style={{ cursor: "pointer" }}
                        alt=""
                    />
                </div>
            </div>
            <div className="content">
                {popups()}
                <div className="notification nanumsquare-15 nanumsquare-13:sm">{t("331")}</div>
                <InputText className="address nanumsquare-15 nanumsquare-13:sm" id="address" placeholder={t("332")} onKeyPress={searchEnterkey} />
                <img
                    id="search-button"
                    onClick={searchAfterCheck}
                    src={process.env.PUBLIC_URL + `/icon_magnifying_glass.png`}
                    srcSet={`${process.env.PUBLIC_URL}/icon_magnifying_glass@2x.png 2x, ${process.env.PUBLIC_URL}/icon_magnifying_glass@3x.png 3x`}
                    alt=""
                />
                <div className="result nanumsquare-15 nanumsquare-13:sm">
                    <Trans i18nKey={"333"} values={{ "%s": paginationOption.current.totalItemsCount }} components={{ tag: <span className="c-4270e0" /> }} />
                </div>
                {addressTable()}
                <div className="button-group" style={{ textAlign: "right" }}>
                    <button className="confirm nanumsquareb-14 nanumsquareb-12:sm" id="confirm" onClick={addressSearchConfirm}>
                        {t("79")}
                    </button>
                    <button className="cancel nanumsquareb-14 nanumsquareb-12:sm" onClick={() => props.onCancel()}>
                        {t("80")}
                    </button>
                </div>
            </div>
        </ReactModal>
    ) : (
        <ReactModal
            isOpen={true}
            // onRequestClose={() => {
            //     props.onCancel();
            // }}
            className={props.className ? props.className : "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"address-search"}
        >
            <div className="title nanumsquareeb-18 nanumsquareeb-16:sm">
                {t("330")}
                <div>
                    <img
                        src={process.env.PUBLIC_URL + `/icon_close.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`}
                        onClick={() => {
                            props.onCancel();
                        }}
                        style={{ cursor: "pointer" }}
                        alt=""
                    />
                </div>
            </div>
            <div className="content">
                {popups()}
                <div className="notification nanumsquare-15 nanumsquare-13:sm">{t("331")}</div>
                <InputText className="address nanumsquare-15 nanumsquare-13:sm" id="address" placeholder={t("332")} onKeyPress={searchEnterkey} />
                <img
                    id="search-button"
                    onClick={searchAfterCheck}
                    src={process.env.PUBLIC_URL + `/icon_magnifying_glass.png`}
                    srcSet={`${process.env.PUBLIC_URL}/icon_magnifying_glass@2x.png 2x, ${process.env.PUBLIC_URL}/icon_magnifying_glass@3x.png 3x`}
                    alt=""
                />
                {state.trySearch && addressTable()}
            </div>
        </ReactModal>
    );
};

export { AddressSearch };
