import React, { useState, useEffect, useRef, useContext } from "react";
import Editor from "../TinyEditor";
import Api from "../../Api";
import { useIsMount } from "../../common/customHook";
import common from "../../common/common";
import { AppContext } from "../../AppContext";
import { Container } from "react-bootstrap";
import { utility } from "@ocean-knight/shared";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";
import { NotionRenderer } from "react-notion-x";
import "react-notion-x/src/styles.css";

export default function UserGuide() {
    const { t } = useTranslation();
    const [recordMap, setRecordMap] = useState(undefined);
    const isMount = useIsMount();
    const context = useContext(AppContext);

    /**
     * 페이지 최초 접근 시 화면에 표시 될 Notion Data를 가져와 저장
     */
    useEffect(() => {
        document.getElementById("App").className = "more";
        const fetchRecordMap = async () => {
            try {
                // FIXME: 기획팀 : `영문 페이지에도 한글 페이지 그대로 넣어주세요.`
                const pageUID = common.getLang() == "ko" ? "120b56c32015800fbdf1d12f0b32928a" : "120b56c32015800fbdf1d12f0b32928a";
                const response = await Api.fetchNotionPage({pageUID});
                setRecordMap(response);
            } catch (e) {
                console.warn("failed to get notion page : ", e);
            }
        };
        fetchRecordMap();
    }, []);

    return (
        <Container>
            <div className="more-sub-title">
                <span className="adobe-gothic-std-75 adobe-gothic-std-40:sm c-fff more">User Guide</span>
                <span className="notosanskr-24b notosanskr-14b:sm c-fff">{t("620")}</span>
            </div>
            <div>
                <NotionRenderer recordMap={recordMap} fullPage={true} darkMode={false} />
            </div>
        </Container>
    );
}
